<template>
  <footer class="footer bg-dark text-white pt-3 pb-2">

    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col">
          <div class="col-12 col-md-6 text-center">
            <h2 class="footer__logo">
              <img src="../assets/logo-ccmc.png" alt="Logo">
            </h2>
          </div>
        </div>
        <div class="col-6 col-md-3 text-start ml-5">
          <h4><router-link id="ccmc_footer_contact" to="/contactUs">{{ $t("footer.contact") }}</router-link></h4>
          <div><strong>{{ $t('footer.emailLabel') }}: </strong> {{ $t('footer.contactEmail') }}</div>
          <div><strong>{{ $t('footer.phoneLabel') }}: </strong> {{ $t('footer.contactPhone') }}</div>
          <div><strong>{{ $t('footer.whatsappLabel') }}: </strong> {{ $t('footer.contactPhone') }}</div>
          <div>&nbsp;</div>
          <div>
            <small class="versions"> {{ $t('footer.version.backend') }}:</small> <strong class="versions">{{systemsVersion.backEndVersion}},&nbsp;</strong>
            <small class="versions"> {{ $t('footer.version.frontend') }}:</small> <strong class="versions">{{frontEndVersion}},&nbsp;</strong>
            <small class="versions"> {{ $t('footer.version.database') }}:</small> <strong class="versions">{{systemsVersion.databaseVersion}}</strong>
          </div>
        </div>
        <div class="col-6 col-md-3 text-end text-md-start">
          <div>
            <div class="mt-2 d-flex">
              <a id="ccmc_footer_facebook" title="Facebook"
                 href="https://www.facebook.com/profile.php?id=61558426510020" target="_blank" role="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="white" class="bi bi-facebook"
                     viewBox="0 0 16 16">
                  <path
                      d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-danger text-white text-center footer--terms-policy pb-2 pt-1 mt-3">
      <div class="row">
        <div class="col">
          <label class="form-check-label" for="confCheckTerms">
            <a :href="getUrl()" class="link-light" target="_blank"
               id="ccmc_footer_termsConditions">{{ $t('opportunity.step4.termCheckLink') }}</a>
          </label>
          <span> | </span>
          <label class="form-check-label" for="policyPrivacy">
            <a :href="getUrl()" class="link-light" target="_blank" id="ccmc_footer_privacyPolicy">
              {{ $t('footer.privacyPolicy') }}</a>
          </label>
        </div>
      </div>

    </div>
    <div class="container-fluid footer--copyright pt-1 pb-1">
      <div class="row">
        <div class="col-12 text-center mt-1">
          <p>&copy;{{currentYear}} {{ $t('footer.copyright') }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapGetters} from "vuex";
import {ApiService} from "@/rest";
import {FE_SYSTEM_VERSION} from "@/systemVersion.js";
import {SystemVersions} from "@/entities.js";
import {buildUrl} from "@/helper";

export default {
  name: "PageFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      systemsVersion: new SystemVersions(),
      frontEndVersion: FE_SYSTEM_VERSION
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "isImpersonalizator"])
  },
  methods:{
    loadSystemVersion() {
      ApiService.GET(
          "/globalParameter/systemsVersion" ,(err, apiResponse) => {
            if (!err) {
              this.systemsVersion = apiResponse;
            }
          }
      );
    },
    getUrl(){
      return buildUrl('legal/TermsAndConditionsOfService.pdf');
    }
  },
  created() {
    this.loadSystemVersion();
  }
};
</script>