import { ApiService } from '@/rest.js';
import { CONTEXT_PATH } from "@/constants.js";
import {i18n} from "@/main";

let statusTypes = [];

export function getTokenInAppCookie() {
  const cookieName = "CCMC_JWT_Token=";
  const cookieList = document.cookie.split(';');

  for (let i = 0; i < cookieList.length; i++) {
    let cookie = cookieList[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length);
    }

    if (cookie.indexOf(cookieName) === 0) {
      return decodeURIComponent(cookie.substring(cookieName.length, cookie.length));
    }
  }
}

export function getAllStatus(callback) {
  if(statusTypes.length == 0){
    ApiService.GET("/opportunity/allStatus", (err, modelResponse) => {
      statusTypes = modelResponse.data;
      callback(statusTypes);
    });
  }else {
    callback(statusTypes);
  }
}

//Convert string in the ISO-8601 format: '2013-03-10T02:00:00Z' to 'YYYY-MM-DD' format
export function formatIso860ToYYYYMMDD(iso8601FormatedValue) {

    let date = new Date(iso8601FormatedValue);
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return year+'-' + month + '-'+dt;
}

export function getToday() {
  return formatDateToString(new Date());
}

export function getOneMonthAgo() {
  const oneMonthAgo = new Date();

  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  return formatDateToString(oneMonthAgo);
}

export function get1970() {
  const veryLongAgo = new Date();

  veryLongAgo.setYear(1970);

  return formatDateToString(veryLongAgo);
}

//Convert a Date to a string with format 'YYYY-MM-DD'
export function formatDateToString(d) {
  
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  let year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

//Convert a String with 'YYYY-MM-DD' format to Date
export function convertToDate(valueWith_YYYYMMDD_format) {

  let parts = valueWith_YYYYMMDD_format.split("-")
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function fromDatePickerToDate(datePickerValue) {

  let dateValue = convertToDate(datePickerValue);
  dateValue.setUTCHours(0,0,0,0);

  return dateValue;
}

export function formatStartingDateTime(datePickerValue) {
  return fromDatePickerToDate(datePickerValue).toISOString()
}

export function formatEndingDateTime(datePickerValue) {

  let dateValue = convertToDate(datePickerValue);
  dateValue.setUTCHours(23,59,59,999);

  return dateValue.toISOString()
}

export function titleCase(text) {
  let words = text.replace('_', ' ').split(' ')
  let res = []

	for (let word of words) {
    res.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  }
  
	return res.join(' ');
}

export function debounce(f, millis) {
  let t;

  return function() {
    let that = this;
    let args = arguments;
    let cb = function() {
      f.apply(that, args);
    };
    clearTimeout(t);
    t = setTimeout(cb, millis);
  };
}

export function formatAmount(amount) {
  if (amount || amount === 0) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return "";
}

export function buildUrl(resourcePath) {
  return CONTEXT_PATH + "/" + i18n.locale + "/" + resourcePath;
}

export function formatIso8601ToShorTime(dateIso8601) {
  let date = new Date(dateIso8601);

  return i18n.t("commons.dateFormats.shortTime",
      { year : date.getFullYear(),
        month : (date.getMonth()+1+"").padStart(2,'0'),
        day : (date.getDate()+"").padStart(2,'0'),
        hour: (date.getHours()+"").padStart(2,'0'),
        minutes: (date.getMinutes()+"").padStart(2,'0'),
        seconds: (date.getSeconds()+"").padStart(2,'0')
      }
  );
}

export function priceFormatter(amount, currency) {

  let simbol = (currency === 'CRC') ? '₡' : '$';

  return simbol + ' ' + amount.toString()
      .split("").reverse().join("")
      .replace(/(\d{3}\B)/g, "$1,")
      .split("").reverse().join("");
}

export function ccmcRedirect(ccmcRouter, path){
  ccmcRouter.push(path).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
}

export function buildWhatsappPreviewUrl(phone, message, opportunity, serverUrl ) {
  return "https://api.whatsapp.com/send?phone=" + "506" + phone + "&text=" + message +
      opportunity.brand + " " + opportunity.model + " " + opportunity.year + ". " +
      serverUrl + "rest/api/v1/preview/" + opportunity.id;
}
